.jq-text .row {
  margin-left: 0;
}

.jq-text .form-group label {
  display:block;
}

.jq-text {
    margin: 10px auto;
}

.dialog-conteiner {
  max-width: 385px;
}

.error-input {
    color: red;
}

.jq-title h2, .jq-title-success h2 {
  font-family: Arial, sans-serif;
  font-size: 1.2em;
  font-weight: 700;
  text-transform: uppercase;
}

.jq-title, .jq-title-success {
    margin: 50px 0 30px;
    text-align: center;
}

.jq-text .form-group {
  margin-bottom: 5px;
}

.jq-text input[type="text"], .jq-text input[type="email"], .jq-text input[type="password"], .jq-text input[type="tel"], .jq-text textarea {
    width: 100%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 14px;
    padding: 10px;
    color: #5f5f5f;
    border: 1px solid #959595;
    outline: none;
    margin: 0;
}

.btn-holder {
  padding-left: 30%;
  padding-bottom: 20px;
}

.dialog-conteiner a.btn {
    width:70px;
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    padding: 0 12px;
    line-height: 36px;
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;
    text-transform: uppercase;
    border: 1px solid #19415c;
    background-color: #2f6385;
    margin-left:5px;
}
.dialog-close-delete {
    float: left;
    margin: 10px 35px 0 105px;
}
.dialog-close-delete-success {
   left: 50%;
    position: absolute;
    margin-left: -35px;
}

.fancybox-outer #header {
  position: initial;
}
.fancybox-outer .app-wrapper {
  padding-top:0;
}
.fancybox-outer #container {
  background-color: #fff;
}
.fancybox-outer footer {
  position: relative;
}
.msg-404 {
   color: #3f3f3f;
    font-size: 17px;
    margin: 0 auto;
    width: 489px;
    padding: 50px 0 50px 0px;
}
.app-article-item .op_b a {
  color: #3f3f3f;
}
._sticker a {
  color: #fff;
}
footer .op_b a, ._subtitle .op_b a {
  color: #fff;
}
.app-contact-item .row a, .app-contact-item a {
  color: #171717;
}
._link._pdf .app-circle-icon .fa {
    vertical-align: text-top;
    margin: 4px 0 0;
}

._link._pdf .fa-file-pdf-o {
    color: #f01d1d;
    font-size: 15px;
}

.app-accordeon-item__title ._round-icon i {
    background-color: #00aeef;
}

#header {
  right: 0;
  width: 100%;
}
.gold-v-protection {
  width:125px;
  height:125px;
}